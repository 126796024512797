import React from 'react'
import { c } from '../utils'

export default ({ children }) => (
  <div className='small banner'>
    <div className='banner-content'>
      {children}
    </div>
  </div>
)
