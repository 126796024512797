import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class PortfolioPreview extends React.Component {

  render() {
    return (
      <div className="item">
        <div className="mosaic-block cover">
          <a className='fancy mosaic-backdrop'>
            {this.renderThumbnails()}
          </a>
          <div className="mosaic-overlay" style={{display: 'block', left: '0px', bottom: '-100px'}}>
            <p className="m0 p0">{this.props.title}</p>
            <a className="small" href={this.props.url} target="_blank">Visit Website →</a> </div>
          </div>
      </div>
    )
  }

  renderThumbnails() {
    if (!this.props.thumbnails) {
      return null;
    }
    return (
      <Img fluid={this.props.thumbnails[0].fluid} />
    );
  }

}

export default PortfolioPreview