import React from 'react';
import Banner from '../components/banner';
import { Link, graphql } from 'gatsby';
import Layout from "../components/layout";
import PortfolioPreview from '../components/portfolio-preview';
import _ from 'lodash';

class Portfolio extends React.Component {
  render() {
    const posts = _.get(this, 'props.data.allContentfulPortfolioItem.edges')

    return (
      <Layout location={this.props.location} >
        <Banner>
          <h1>Previous Works</h1>
        </Banner>
        <div className='spotlight-divider' id="portfolio">
          {posts.map(({ node }, idx) => <PortfolioPreview {...node} key={idx} />)}
          <div className="clear"></div>
        </div>
      </Layout>
    )
  }
}

export default Portfolio

export const pageQuery = graphql`
  query PortfolioQuery {
    allContentfulPortfolioItem(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          title
          url
          thumbnails {
            fluid(maxWidth: 300){
              ...GatsbyContentfulFluid_withWebp
            }
          }
          screenshots {
            fluid(maxWidth: 960){
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
